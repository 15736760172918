<template>
   <iframe :src="iframeSrc" frameborder="0" scrolling="no" ref="iframe"></iframe>
</template>

<script setup>
    import { computed } from 'vue';

    const iframe = ref(null);

    const props = defineProps({
        tripadvisor: {
            type: String,
            required: false,
        },
        tripadvisor_script: {
            type: String,
            required: false,
        },
    });

    const encodedWidgetHtml = computed(() => {
        return props.tripadvisor ? encodeURIComponent(props.tripadvisor) : '';
    });

    const encodedWidgetScript = computed(() => {
        return props.tripadvisor_script ? encodeURIComponent(props.tripadvisor_script) : '';
    });

    const iframeSrc = computed(() => {
        let url = '/trustpilot-widget.html';

        const params = [];

        if (encodedWidgetHtml.value) {
            params.push(`widgetHtml=${encodedWidgetHtml.value}`);
        }

        if (encodedWidgetScript.value) {
            params.push(`widgetScript=${encodedWidgetScript.value}`);
        }

        if (params.length) {
            url += '?' + params.join('&');
        }
        
        return url;
    });

    onMounted(() => {
        window.addEventListener('message', (event) => {
            if (event.data?.type === 'trustpilot-resize' && iframe.value) {
                iframe.value.style.height = `${event.data.height}px`;
            }
        });
    });
</script>
